<app-page-structure>
  <h1 class="title">{{ 'search.title' | translate }}</h1>

  @if (!searching) {
    <p class="description">{{ 'search.description' | translate }}</p>

    <form class="term-form">
      <mat-form-field class="term-field">
        <mat-label>{{ 'search.termSearchPlace' | translate }}</mat-label>
        <input id="term" type="email" matInput [formControl]="termFormControl" [errorStateMatcher]="matcher"
               placeholder="Ex. tux@linux.org" autocomplete="off">
        @if (termFormControl.valid) {
          <button matSuffix mat-icon-button aria-label="Clear" (click)="doSearch(termFormControl.value)">
            <span class="material-symbols-outlined">search</span>
          </button>
        }
        @if (termFormControl.hasError('term') && !termFormControl.hasError('invalid') && !termFormControl.hasError('required')) {
          <mat-error>{{ 'search.error.-3' | translate }}</mat-error>
        }
        @if (termFormControl.hasError('invalid') && !termFormControl.hasError('required')) {
          <mat-error>{{ 'search.error.-2' | translate }}</mat-error>
        }
        @if (termFormControl.hasError('required')) {
          <mat-error>{{ 'search.error.-1' | translate }}</mat-error>
        }
      </mat-form-field>
    </form>

    @if (showHistory) {
      <mat-list>
        @for (item of history; track item) {
          <mat-list-item matRipple (click)="doSearch(item.term)" class="history-item">
            <mat-icon matListItemIcon>history</mat-icon>
            <div matListItemTitle>{{ item.term }}</div>
          </mat-list-item>
        }
      </mat-list>
    }
  }

  <router-outlet></router-outlet>
</app-page-structure>
