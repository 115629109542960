{
  "name": "certified-ui",
  "version": "2.4.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0 --disable-host-check --proxy-config proxy.config.js --verbose",
    "start-ssl": "ng serve --host 0.0.0.0 --disable-host-check --ssl --ssl-key localhost.key --ssl-cert localhost.crt --proxy-config proxy.config.js --verbose",
    "build-stage": "ng build --configuration stage --aot --progress --verbose --source-map=true --base-href=/ --output-path=dist/certified",
    "build": "ng build --configuration production --aot --progress --verbose --source-map=true --base-href=/ --output-path=dist/certified",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.0.9",
    "@angular/cdk": "17.0.5",
    "@angular/common": "17.0.9",
    "@angular/compiler": "17.0.9",
    "@angular/core": "17.0.9",
    "@angular/forms": "17.0.9",
    "@angular/material": "17.0.5",
    "@angular/platform-browser": "17.0.9",
    "@angular/platform-browser-dynamic": "17.0.9",
    "@angular/router": "17.0.9",
    "@angular/service-worker": "17.0.9",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "8.0.0",
    "rxjs": "7.8.1",
    "tslib": "2.6.2",
    "zone.js": "0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.0.10",
    "@angular/cli": "17.0.10",
    "@angular/compiler-cli": "17.0.9",
    "@types/jasmine": "5.1.4",
    "jasmine-core": "5.1.1",
    "karma": "6.4.2",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "typescript": "5.2.2"
  }
}
