<div class="toolbar" role="banner">

  <a href="/">
    @if (darkMode) {
      <img ngSrc="assets/images/logo-slim-white.svg" class="logo" alt="FLISoL" height="40" width="89"
           [priority]="true"/>
    } @else {
      <img ngSrc="assets/images/logo-slim.svg" class="logo" alt="FLISoL" height="40" width="89" [priority]="true"/>
    }
  </a>

  <div class="spacer"></div>

  <button mat-icon-button (click)="toggleColorScheme()"
          matTooltip="{{ (darkMode ? 'common.turnOnLightMode' : 'common.turnOnDarkMode') | translate }}"
          [matTooltipPosition]="'below'">
    @if (darkMode) {
      <mat-icon>dark_mode</mat-icon>
    } @else {
      <mat-icon>light_mode</mat-icon>
    }
  </button>

  <button mat-icon-button class="language-menu" [matMenuTriggerFor]="menu" xPosition="before"
          [matTooltip]="language.name" [matTooltipPosition]="'below'">
    @if (darkMode) {
      <img [ngSrc]="'assets/images/flags/' + language.flag.toLowerCase() + '-white.svg'" alt="" height="18" width="24"/>
    } @else {
      <img [ngSrc]="'assets/images/flags/' + language.flag.toLowerCase() + '.svg'" alt="" height="18" width="24"/>
    }
  </button>
  <mat-menu #menu="matMenu">
    @for (item of languageService.getLanguages(); track $index) {
      <button mat-menu-item class="language-menu-item" [disabled]="item.code === language.code"
              (click)="selectLanguage(item)">
        @if (darkMode) {
          <img [ngSrc]="'assets/images/flags/' + item.flag.toLowerCase() + '-white.svg'" alt="" height="18" width="24"/>
        } @else {
          <img [ngSrc]="'assets/images/flags/' + item.flag.toLowerCase() + '.svg'" alt="" height="18" width="24"/>
        }
        <span>{{ item.name }}</span>
      </button>
    }
  </mat-menu>
</div>
